import { 
  Primary,
  Secondary,
  Tertiary
} from './Text';

export default Object.assign({}, {
  Primary,
  Secondary,
  Tertiary
});